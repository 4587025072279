<template>
  <div class="info">
    <div>
      <el-col :span="20">
        <el-descriptions v-if="isVisible" title="基本信息" :column="1" labelClassName="des-item" border>
          <el-descriptions-item label="姓名">{{ userInfo.realName }}</el-descriptions-item>
          <el-descriptions-item label="职业">{{ userInfo.professional }}</el-descriptions-item>
          <el-descriptions-item label="学校/单位">{{ userInfo.school }}</el-descriptions-item>
          <el-descriptions-item label="院系/部门">{{ userInfo.department }}</el-descriptions-item>
          <el-descriptions-item label="昵称">{{ this.$store.state.user.name }}</el-descriptions-item>
          <el-descriptions-item label="性别">{{ getSex(userInfo.sex) }}</el-descriptions-item>
          <el-descriptions-item label="入学年份">{{ userInfo.schoolYear }}</el-descriptions-item>
          <el-descriptions-item label="最高学历">{{ userInfo.highestRecord }}</el-descriptions-item>
          <el-descriptions-item label="简介">{{ userInfo.introduction }}</el-descriptions-item>
        </el-descriptions>
        <div v-else>
          <el-form ref="elForm" :model="user" :rules="rules" size="medium" label-width="100px" label-position="left">
            <el-form-item label="真实姓名" prop="realName">
              <el-input v-model="user.realName" placeholder="请输入真实姓名" :maxlength="100" clearable
                        prefix-icon='el-icon-user' :style="{width: '100%'}"></el-input>
            </el-form-item>
            <el-form-item label="职业" prop="professional">
              <el-input v-model="user.professional" placeholder="请输入职业" :maxlength="20" clearable
                        prefix-icon='el-icon-s-promotion' :style="{width: '100%'}"></el-input>
            </el-form-item>
            <el-form-item label="学校/单位" prop="school">
              <el-input v-model="user.school" placeholder="请输入学校/单位" :maxlength="100" clearable
                        prefix-icon='el-icon-school' :style="{width: '100%'}"></el-input>
            </el-form-item>
            <el-form-item label="院系/部门" prop="department">
              <el-input v-model="user.department" placeholder="请输入院系/部门" :maxlength="100" clearable
                        prefix-icon='el-icon-s-data' :style="{width: '100%'}"></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="sex">
              <el-radio-group v-model="user.sex" size="medium">
                <el-radio-button v-for="(item, index) in sexOptions" :key="index" :label="item.value"
                                 :disabled="item.disabled">{{ item.label }}
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="入学年份" prop="schoolYear">
              <el-date-picker type="year" v-model="user.schoolYear" format="yyyy" value-format="yyyy"
                              :style="{width: '100%'}" placeholder="请选择入学年份" clearable>

              </el-date-picker>
            </el-form-item>
            <el-form-item label="最高学历" prop="highestRecord">
              <el-select v-model="user.highestRecord" placeholder="请选择最高学历" clearable :style="{width: '100%'}">
                <el-option v-for="(item, index) in highestRecordOptions" :key="index" :label="item.label"
                           :value="item.value" :disabled="item.disabled"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="个人介绍" prop="introduction">
              <el-input v-model="user.introduction" type="textarea" placeholder="请输入个人介绍" :maxlength="1000"
                        :autosize="{minRows: 4, maxRows: 4}" :style="{width: '100%'}"></el-input>
            </el-form-item>
            <el-form-item size="large">
              <el-button type="primary" @click="submitForm">提交</el-button>
              <el-button @click="resetForm">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" @click="change">
          {{ message }}
        </el-button>
      </el-col>
    </div>
  </div>
</template>

<script>
import {updateUserInfo, getUserInfoByUserId} from "@/api/user";
import global from "@/config/global";
import user from "@/store/modules/user";

export default {
  name: "ModifyInfo",
  data() {
    return {
      temp: {},
      userInfo: {},
      message: "修改",
      isVisible: true,
      uploadUrl: global.uploadUrl,
      user: {
        id: this.$store.state.user.id,
        realName: undefined,
        professional: undefined,
        school: undefined,
        department: undefined,
        sex: 1,
        schoolYear: "2012",
        highestRecord: "研究生",
        introduction: undefined,
      },
      rules: {
        realName: [{
          required: true,
          message: '请输入真实姓名',
          trigger: 'blur'
        }],
        professional: [{
          required: true,
          message: '请输入职业',
          trigger: 'blur'
        }],
        school: [{
          required: true,
          message: '请输入学校/单位',
          trigger: 'blur'
        }],
        department: [{
          required: true,
          message: '请输入院系/部门',
          trigger: 'blur'
        }],
        sex: [{
          required: true,
          message: '性别不能为空',
          trigger: 'change'
        }],
        schoolYear: [{
          required: true,
          message: '请选择入学年份',
          trigger: 'change'
        }],
        highestRecord: [{
          required: true,
          message: '请选择最高学历',
          trigger: 'change'
        }],
        introduction: [{
          required: true,
          message: '请输入个人介绍',
          trigger: 'blur'
        }],
      },
      sexOptions: [{
        "label": "男",
        "value": 1
      }, {
        "label": "女",
        "value": 2
      }],
      highestRecordOptions: [{
        "label": "初中",
        "value": "初中"
      }, {
        "label": "高中",
        "value": "高中"
      }, {
        "label": "本科",
        "value": "本科"
      }, {
        "label": "研究生",
        "value": "研究生"
      }, {
        "label": "博士",
        "value": "博士"
      }],
    }
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
    this.get()
  },
  methods: {
    get() {
      getUserInfoByUserId(this.$store.state.user.id).then(result => {
        this.isVisible = true;
        this.userInfo = result.data.user;
        this.user = JSON.parse(JSON.stringify(result.data.user));
        this.user.id = this.$store.state.user.id;
        this.user.schoolYear = this.user.schoolYear + ""
      })
    },
    change() {
      this.isVisible = !this.isVisible;
      if (this.isVisible === true) {
        this.message = "修改"
      } else {
        this.message = "取消"
      }
    },
    getSex(sex) {
      if (sex === 1) {
        return "男"
      } else {
        return "女"
      }
    },
    handleAvatarSuccess(res, file) {
      this.user.userImg = res.data.url;
    },
    submitForm() {
      // console.log(this.user)
      let that=this;
      let temp = JSON.parse(JSON.stringify(this.user));
      temp.schoolYear = parseInt(temp.schoolYear);
      updateUserInfo(temp).then(result => {
        that.$message(result.data.user)
        that.get()
      }).catch(error => {
        that.$message(error)
      })
      // updateUserInfo(this.user)
      // this.$refs['elForm'].validate(valid => {
      //   if (!valid) return
      //   // TODO 提交表单
      // })
    },
    resetForm() {
      console.log("重置开始");
      this.user = JSON.parse(JSON.stringify(this.userInfo));
      this.user.id = this.$store.state.user.id;
      this.user.schoolYear = this.user.schoolYear + ""
    },

    // 更新用户信息
    // submitForm(){
    //   updateInfo(this.user).then(result=>{
    //     this.$message(result.data.user);
    //     this.$store.dispatch("user/getInfo");
    //     this.$router.push("/")
    //   }).catch(error=>{
    //     this.$message(error.message)
    //   })
    // },
    // resetForm(formName){
    //   this.$refs[formName].resetFields();
    // }
  }
}
</script>

<style scoped>

/*  左边信息*/
.info {
  padding-left: 30px;
  padding-top: 30px;
  margin: 0 !important;
  min-height: 400px;
  width: 100%;
}

.el-descriptions-item__container {
  height: 30px;
  line-height: 30px;
}

/* 图片信息*/
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
